.personinfo {
    .item-group {
        margin-top: 15px;
    }
    .item {
        clear: both;
        min-height: 1px;
        border-bottom: 1px dotted #999;
        overflow: hidden;
    }

    .panel-heading .item {
        border-bottom: none;
    }

    .item {
        margin-top: 3px;
    }

    .key {
        float: right;
        font-size: 85%;
        font-style: italic;
        color: #999;
        padding-right: 1ex;
    }
    .value {
    }

    .panel > .key,
    .panel > .value {
        float: none;
        width: 100%;
        max-width: 100%;
    }

    .panel > .key::after {
        content: none;
    }
}

.password {
    #new-password-form {
        margin-top: 10px;
        .input-group {
            width: 100%;
        }
        .form-group {
            width: 100%;
        }
    }
    #send-password-form {
        margin-top: 10px;
    }
}

#showcalendar {
    border: 0.5px solid lightgrey;
    padding: 10px;
    margin: 3px;
    border-radius: 5px;

    h3 {
        font-size: 16px!important;
        font-weight: normal;
    }
    a {
        margin-top: 0!important;
        float: right;
    }

}

.actionbox {
    height: 270px;
    width: 250px;
    border: 0.5px solid lightgrey;
    padding: 10px;
    margin: 3px;
    border-radius: 5px;
    text-align: center;
}
.actionbtn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    alignment: center;
    margin-top: 20px;
}

.btn-success {
  background-color: #198754;
}

.actionbox-header {
    text-align: center;
}

.kartbox {
    width: 100%;
    border: 0.5px solid lightgrey;
    padding: 10px;
    margin: 3px;
    border-radius: 5px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.kartbox-item {
    display: inline-block;
    vertical-align: middle
}
.kartbtn {
    //width: 120px;
    //margin-left: 70px;
}
.kartbox-txt {
    margin-left: 20px;
}
