@import "variables";

.registration {

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  form {
    .control-label {
      white-space: nowrap;
    }
  }

  .dvDateDropdown {
    display: flex;
    flex-wrap: wrap;

    > select {
      width: 30%;
    }
  }
}

.errordescr {
  color: red;
}

div[field="samtykke"], div[field="consent"], div[field="approved_terms"] {
  display: flex;
}



