@import "variables";

.sidebar {
  p {
    font-size: 90%;

    a {
      font-size: inherit;
    }
  }

  //border-left: 1px solid #eee;
  @media only screen and (max-width: @phone-size) {
    border-left: none;
    margin-top: 5rem;

    aside p {
      font-size: 1.5rem;
    }
  }
}

