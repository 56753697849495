@import "variables";

html {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  line-height: 160%;
}


body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
  }
}

h1, h2, h3, h4 {
  font-weight: bold;

}

main {
  //& > h1 {
  //  font-size: 6rem;
  //}

  & > section {
    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  .errordescr {
    color: red;
  }
}
