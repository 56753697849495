/*
    This file is for calculating values for
    the theme-cssvars-defaults file.

    You should not import this file anywhere, just copy
    the generated values.
 */
:root {
    --innkjop-base-color: #012a4c;
    --innkjop-secondary-color: #0064b4;
    --innkjop-background-color: #f9f9f9;
    --innkjop-widget-background-color: #fefefe;
    --innkjop-neutral-color-3: #f7f7f7;
    --innkjop-neutral-color-5: #f2f2f2;
    --innkjop-neutral-color-10: #e6e6e6;
    --innkjop-neutral-color-20: #cccccc;
    --innkjop-neutral-color-40: #999999;
    --innkjop-neutral-color-60: #666666;
    --innkjop-neutral-color-80: #333333;
    --innkjop-neutral-color-100: #000000;
    --innkjop-theme-color-10: #e6f0f5;
    --innkjop-theme-color-20: #cce0eb;
    --innkjop-theme-color-40: #87b7cf;
    --innkjop-theme-color-60: #66a3c2;
    --innkjop-theme-color-80: #3384ad;
    --innkjop-theme-color-100: #006699;
    --innkjop-contrast-color-10: #e6f5f1;
    --innkjop-contrast-color-20: #ccebe3;
    --innkjop-contrast-color-40: #87cfbe;
    --innkjop-contrast-color-60: #66c2ac;
    --innkjop-contrast-color-80: #33ad90;
    --innkjop-contrast-color-100: #009975;
}
