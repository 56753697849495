@import "variables";

body > header {

  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;

  a {
    &:hover {
      text-decoration: none;
    }

    h1 {
      color: transparent;
      font-weight: bold;
      font-size: 80px;
    }
  }
}

.dk-fully-loaded body > header a h1{
        color: @soa-color;
}

.navbar {
  background-color: #333;
  background-image: none;
  a {
    color: #9d9d9d;
  }
  .container {
    //width: 70%;
  }
}

.navbar-brand {
  width: 300px !important;
  font-size: 2rem;

  a {
    text-decoration: none;
  }
  img {
    position: absolute;
    height: 50px;
    top: 5px;
    left: 10px;
  }
}

.navbar-text {
  font-size: 1rem;

  a {
    color: #9d9d9d;
  }
}
