@import "theme-less-vars";

.depth-1 { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
.depth-2 { box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
.depth-3 { box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.depth-4 { box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }
.depth-5 { box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); }
//.depth-1:hover {   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }

.card-row {
  display: flex;
  flex-direction: row;
}
.card {
  padding: @gutter-width;
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  position: relative;
  //box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow: hidden;
  //margin: @gutter-width;
  border: 0.5px solid lightgrey;

  @h1-font-size: 30px;

  h1,h2,h3,h4,h5,h6 {
    margin-top:0;
    >small {
      display: block;
      clear: both;
      margin-top: 0.3ex;
    }
  }
  > header {margin-bottom: 1ex; }

  h1 { font-size: @h1-font-size; }
  h2 { font-size: @h1-font-size * 0.9; }
  h3 { font-size: @h1-font-size * 0.8; }
  h4 { font-size: @h1-font-size * 0.7; }
  h5 { font-size: @h1-font-size * 0.7; }
  h6 { font-size: @h1-font-size * 0.7; }

  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  // offset-x offset-y blur-radius color
}
