
@import "theme-less-vars";

.modal-page {
    min-height: auto!important;
    height: auto!important;

    h1 {
        font-size: clamp(1.5rem, 5vw, 2rem);
    }

    &.ok {background-color: @base-color;}
    &.danger {background-color: @base-color;}
    &.error {background-color: @error-color;}
    &.warning {background-color: @warning-color;}
    &.info {background-color: @info-color;}

    .modal-box {
        @padding: 60px;
        display: flex;
        position: relative;
        flex-direction: column;
        align-content: space-between;
        border: 1px solid #999;
        border-radius: 6px;
        background-color: @background-color;

        margin-top: 5%;
        margin-bottom: 5%;
        max-width: 800px;
        padding: @padding;
        min-height: 400px;

        @media screen and (max-width: @screen-xs-max) {
            @margin: 10px;
            max-width: calc(100% - 2 * @margin);
            max-height: calc(100% - 2 * @margin);
            margin-top: @margin;
            padding: @padding / 2;

            //header .logo {
            //    max-width: 400px;
            //}
        }

        > header {
            border-bottom: 1px solid @neutral-color-20;
            padding-bottom: @gutter-width;
            margin-bottom: @gutter-width;
            .logo {
                width: clamp(100px, 10vw, 150px);
            }
        }

        > footer {
            hr {
                width: 30%;
                text-align: left;
            }
        }
    }
}
