.payment-logo {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    a {
      text-decoration: none;

      img {
        max-width: 23%;
        width: 40px;
      }
    }
  }
