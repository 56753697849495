html {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  line-height: 160%;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body main {
  flex: 1;
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
}
main > section h1 {
  font-size: 2.5rem;
}
main > section h2 {
  font-size: 2rem;
}
main .errordescr {
  color: red;
}
body > header {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}
body > header a:hover {
  text-decoration: none;
}
body > header a h1 {
  color: transparent;
  font-weight: bold;
  font-size: 80px;
}
.dk-fully-loaded body > header a h1 {
  color: #1C75DA;
}
.navbar {
  background-color: #333;
  background-image: none;
}
.navbar a {
  color: #9d9d9d;
}
.navbar-brand {
  width: 300px !important;
  font-size: 2rem;
}
.navbar-brand a {
  text-decoration: none;
}
.navbar-brand img {
  position: absolute;
  height: 50px;
  top: 5px;
  left: 10px;
}
.navbar-text {
  font-size: 1rem;
}
.navbar-text a {
  color: #9d9d9d;
}
.homepage header {
  margin-bottom: 0;
}
.homepage header .hero {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.homepage header .hero h1.main-header {
  font-size: 50px;
}
@media screen and (max-width: 1200px) {
  .homepage header .hero h1.main-header {
    font-size: 40px;
  }
}
@media screen and (max-width: 992px) {
  .homepage header .hero h1.main-header {
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  .homepage header .hero h1.main-header {
    font-size: 20px;
  }
}
.homepage header .hero img.homelogo {
  max-width: 100%;
  width: 40vw;
  min-width: 80px;
  horiz-align: center;
  margin: 2rem 2rem 2rem 0;
}
@media screen and (max-width: 480px) {
  .homepage header .hero img.homelogo {
    max-width: 50px;
    width: 100%;
    margin-right: 3rem;
  }
}
@media screen and (max-width: 992px) {
  .homepage header .hero img.homelogo {
    max-width: 30vw;
    width: 100%;
    margin-right: 3rem;
  }
}
.homepage header .hero img.homelogo > a {
  display: block;
}
.homepage main .main-content {
  font: inherit;
  font-size: 16px;
}
.homepage main .main-content h2 {
  font-size: 30px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 480px) {
  .homepage main .main-content h2 {
    font-size: 2rem;
  }
}
.homepage main .main-content .main-ingress {
  font-weight: 200;
  font-size: 24px;
}
@media only screen and (max-width: 480px) {
  .homepage main .main-content .main-ingress {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .homepage main .main-content .content-body {
    font-size: 1.5rem;
  }
}
.homepage main .soa-links {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  border: 0;
}
.heading-flex-box {
  display: flex;
  vertical-align: middle;
  align-items: center;
}
.sidebar p {
  font-size: 90%;
}
.sidebar p a {
  font-size: inherit;
}
@media only screen and (max-width: 480px) {
  .sidebar {
    border-left: none;
    margin-top: 5rem;
  }
  .sidebar aside p {
    font-size: 1.5rem;
  }
}
.payment-logo {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.payment-logo a {
  text-decoration: none;
}
.payment-logo a img {
  max-width: 23%;
  width: 40px;
}
footer.page-footer {
  box-sizing: border-box;
  position: relative;
  background-color: #000;
  margin-top: 50px;
  font-size: 12px;
}
footer.page-footer .footer-content {
  min-height: 8cm;
  display: flex;
  flex-direction: row;
  justify-content: center!important;
  align-items: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
  letter-spacing: 0.2em;
  color: #fff !important;
}
@media screen and (max-width: 835px) {
  footer.page-footer .footer-content {
    flex-direction: column;
    min-height: 12cm;
  }
  footer.page-footer .footer-content .footer-menu {
    padding-bottom: 4rem;
    margin-top: -4rem;
  }
}
footer.page-footer .footer-content a,
footer.page-footer .footer-content a:hover {
  color: #fff;
}
footer.page-footer .footer-content .footer-label {
  color: #b4b4b4;
}
footer.page-footer .footer-content .norsktest-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
@media screen and (max-width: 835px) {
  footer.page-footer .footer-content .norsktest-footer {
    order: 2;
  }
}
footer.page-footer .footer-content .norsktest-footer .contact-info {
  padding: 0 1cm;
  font-variant: small-caps;
  font-size: 12px;
}
footer.page-footer .footer-content .norsktest-footer .created-by {
  font-weight: 700;
  line-height: 190%;
  text-align: left;
  border-left: 1px solid #b4b4b4;
  padding: 0.5cm 1cm;
  font-size: 12px;
}
footer.page-footer .footer-content .norsktest-footer .created-by a {
  font-size: inherit;
}
@media screen and (max-width: 520px) {
  footer.page-footer .footer-content .norsktest-footer .created-by {
    display: none;
  }
}
footer.page-footer .footer-content .norsktest-footer .info-list {
  list-style-type: none;
}
footer.page-footer .footer-content .footer-menu a {
  display: block;
  font-size: 12px;
}
footer.page-footer .legal {
  position: absolute;
  left: 40px;
  bottom: 20px;
}
footer.page-footer .legal a,
footer.page-footer .legal footer.page-footer .legal a:hover {
  color: #b4b4b4;
  margin-right: 1em;
  font-size: 12px;
}
.registration h2 {
  text-align: center;
  margin-bottom: 30px;
}
.registration form .control-label {
  white-space: nowrap;
}
.registration .dvDateDropdown {
  display: flex;
  flex-wrap: wrap;
}
.registration .dvDateDropdown > select {
  width: 30%;
}
.errordescr {
  color: red;
}
div[field="samtykke"],
div[field="consent"],
div[field="approved_terms"] {
  display: flex;
}
.personinfo .item-group {
  margin-top: 15px;
}
.personinfo .item {
  clear: both;
  min-height: 1px;
  border-bottom: 1px dotted #999;
  overflow: hidden;
}
.personinfo .panel-heading .item {
  border-bottom: none;
}
.personinfo .item {
  margin-top: 3px;
}
.personinfo .key {
  float: right;
  font-size: 85%;
  font-style: italic;
  color: #999;
  padding-right: 1ex;
}
.personinfo .panel > .key,
.personinfo .panel > .value {
  float: none;
  width: 100%;
  max-width: 100%;
}
.personinfo .panel > .key::after {
  content: none;
}
.password #new-password-form {
  margin-top: 10px;
}
.password #new-password-form .input-group {
  width: 100%;
}
.password #new-password-form .form-group {
  width: 100%;
}
.password #send-password-form {
  margin-top: 10px;
}
#showcalendar {
  border: 0.5px solid lightgrey;
  padding: 10px;
  margin: 3px;
  border-radius: 5px;
}
#showcalendar h3 {
  font-size: 16px!important;
  font-weight: normal;
}
#showcalendar a {
  margin-top: 0!important;
  float: right;
}
.actionbox {
  height: 270px;
  width: 250px;
  border: 0.5px solid lightgrey;
  padding: 10px;
  margin: 3px;
  border-radius: 5px;
  text-align: center;
}
.actionbtn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  alignment: center;
  margin-top: 20px;
}
.btn-success {
  background-color: #198754;
}
.actionbox-header {
  text-align: center;
}
.kartbox {
  width: 100%;
  border: 0.5px solid lightgrey;
  padding: 10px;
  margin: 3px;
  border-radius: 5px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.kartbox-item {
  display: inline-block;
  vertical-align: middle;
}
.kartbox-txt {
  margin-left: 20px;
}
/*
    This file is for calculating values for
    the theme-cssvars-defaults file.

    You should not import this file anywhere, just copy
    the generated values.
 */
:root {
  --innkjop-base-color: #012a4c;
  --innkjop-secondary-color: #0064b4;
  --innkjop-background-color: #f9f9f9;
  --innkjop-widget-background-color: #fefefe;
  --innkjop-neutral-color-3: #f7f7f7;
  --innkjop-neutral-color-5: #f2f2f2;
  --innkjop-neutral-color-10: #e6e6e6;
  --innkjop-neutral-color-20: #cccccc;
  --innkjop-neutral-color-40: #999999;
  --innkjop-neutral-color-60: #666666;
  --innkjop-neutral-color-80: #333333;
  --innkjop-neutral-color-100: #000000;
  --innkjop-theme-color-10: #e6f0f5;
  --innkjop-theme-color-20: #cce0eb;
  --innkjop-theme-color-40: #87b7cf;
  --innkjop-theme-color-60: #66a3c2;
  --innkjop-theme-color-80: #3384ad;
  --innkjop-theme-color-100: #006699;
  --innkjop-contrast-color-10: #e6f5f1;
  --innkjop-contrast-color-20: #ccebe3;
  --innkjop-contrast-color-40: #87cfbe;
  --innkjop-contrast-color-60: #66c2ac;
  --innkjop-contrast-color-80: #33ad90;
  --innkjop-contrast-color-100: #009975;
}
.modal-page {
  min-height: auto!important;
  height: auto!important;
}
.modal-page h1 {
  font-size: clamp(1.5rem, 5vw, 2rem);
}
.modal-page.ok {
  background-color: var(--innkjop-base-color);
}
.modal-page.danger {
  background-color: var(--innkjop-base-color);
}
.modal-page.error {
  background-color: #e45d50;
}
.modal-page.warning {
  background-color: #e6a65d;
}
.modal-page.info {
  background-color: var(--innkjop-base-color);
}
.modal-page .modal-box {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: space-between;
  border: 1px solid #999;
  border-radius: 6px;
  background-color: var(--innkjop-background-color);
  margin-top: 5%;
  margin-bottom: 5%;
  max-width: 800px;
  padding: 60px;
  min-height: 400px;
}
@media screen and (max-width: 767px) {
  .modal-page .modal-box {
    max-width: calc(100% - 2 * 10px);
    max-height: calc(100% - 2 * 10px);
    margin-top: 10px;
    padding: 60px / 2;
  }
}
.modal-page .modal-box > header {
  border-bottom: 1px solid var(--innkjop-neutral-color-20);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.modal-page .modal-box > header .logo {
  width: clamp(100px, 10vw, 150px);
}
.modal-page .modal-box > footer hr {
  width: 30%;
  text-align: left;
}
.depth-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.depth-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.depth-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.depth-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.depth-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.card-row {
  display: flex;
  flex-direction: row;
}
.card {
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  border: 0.5px solid lightgrey;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card h1,
.card h2,
.card h3,
.card h4,
.card h5,
.card h6 {
  margin-top: 0;
}
.card h1 > small,
.card h2 > small,
.card h3 > small,
.card h4 > small,
.card h5 > small,
.card h6 > small {
  display: block;
  clear: both;
  margin-top: 0.3ex;
}
.card > header {
  margin-bottom: 1ex;
}
.card h1 {
  font-size: 30px;
}
.card h2 {
  font-size: 27px;
}
.card h3 {
  font-size: 24px;
}
.card h4 {
  font-size: 21px;
}
.card h5 {
  font-size: 21px;
}
.card h6 {
  font-size: 21px;
}
body.receipt div.receipt {
  display: flex;
  justify-content: space-between;
}
body.grouppay-info h1 {
  font-size: 2rem;
}
