@import "variables";

.homepage header {
  margin-bottom: 0;

  .hero {
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid #eee;
    margin-top: 10px;

    //.logo-link {
    //  margin: 2rem 4rem 2rem 2rem;
    //}

    h1.main-header {
      font-size: 50px;

      @media screen and (max-width: @dekstop-size) {
        font-size: 40px;
      }
      @media screen and (max-width: @pad-size) {
        font-size: 30px;
      }

      @media screen and (max-width: @phone-size) {
        font-size: 20px;
      }

    }

    img.homelogo {
      max-width: 100%;
      width: 40vw;
      min-width: 80px;
      horiz-align: center;
      margin: 2rem 2rem 2rem 0;
      @media screen and (max-width: @phone-size) {
        max-width: 50px;
        width: 100%;
        margin-right: 3rem;
      }
      @media screen and (max-width: @pad-size) {
        max-width: 30vw;
        width: 100%;
        margin-right: 3rem;
      }

      > a {
        display: block;
      }
    }
  }
}

.homepage main {

  .main-content {
    font: inherit;
    //line-height: 2;
    font-size: 16px;

    h2 {
      font-size: 30px;
      margin-bottom: 30px;
      @media only screen and (max-width: @phone-size) {
        font-size: 2rem;
      }
    }

    .main-ingress {
      font-weight: 200;
      font-size: 24px;
      @media only screen and (max-width: @phone-size) {
        font-size: 1.5rem;
      }
    }

    .content-body {
      @media only screen and (max-width: @phone-size) {
        font-size: 1.5rem;
      }
    }
  }

  .soa-links {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    border: 0;
  }
}

.heading-flex-box {
  display: flex;
  vertical-align: middle;
  align-items: center;
}
